import React, { Component, useRef } from 'react';
import ReactDOM from 'react-dom/client';

import { Canvas } from "react-three-fiber";
import { OrbitControls, Sphere } from "@react-three/drei";

let cubes = []

const Cube = (props) => {

    return (<>
        <mesh ref={props.cubeRef} position={props.position}>
           <boxGeometry args={[1, 1, 1]}  />
           <meshPhongMaterial color={props.color}  />
       </mesh>
     </>);

}

const playAnimation = () => {
    // time *= 0.001;  // convert time to seconds
    
    cubes.forEach( cube => {
        cube.props.cubeRef.current.rotation.x += 0.001;
        cube.props.cubeRef.current.rotation.y += 0.001;
    } )

    setTimeout(() => playAnimation(),1)
}

const Scene = (props) => {

    setTimeout(() => playAnimation(),1)

    cubes =[];
    
    cubes.push(<Cube key={1} cubeRef={useRef()} color={"#44aa88"} position={[-2,0,0]} />)
    cubes.push(<Cube key={2} cubeRef={useRef()} color={"#8844aa"} position={[0,0,0]} />)
    cubes.push(<Cube key={3} cubeRef={useRef()} color={"#aa8844"} position={[2,0,0]} />)
    
    return (<>
        <directionalLight color={"#ffffff"} intensity={1} position={[-1,2,4]}/>
        {cubes}    
           
    </>
    );
};


class Model3D extends Component {

    constructor(props) {
        super(props)
        this.state = {  } 

    }
    componentDidMount() {
       this.createScene();
    }

    
    createScene = () => {
        const canvas = ReactDOM.createRoot(
            document.getElementById("model")
        );
        canvas.render(
        // <Canvas style={{ height: 400, width: 400 , border: "1px solid black"}}>
        <Canvas
        style={{ height: 400, width: 400 }}
                camera={{
                fov: 75,
                aspect: 2,
                near: 0.1,
                far: 5,
                position: [0,0,2],
                // rotation: [0,0,0]
                }}
            >     
            <Scene />
          </Canvas>
        );

    }

    render() { 
        return (
            <div className='container mcontainer'>
                <div id="model"></div>
               
            </div>    
        );
    }
}
 
export default Model3D;

import linspace from 'exact-linspace';
import React, { useRef } from 'react';
import {useFrame } from "react-three-fiber";
import * as three from "three";
import { BufferGeometry, Vector2,Points,PointsMaterial, Vector3 } from 'three';
import { BufferAttribute } from 'three/src/core/BufferAttribute';

// import {mesh,boxGeometry,meshStandardMaterial} from "three";

function randNum(min, max) {
  // 👇️ get number between min (inclusive) and max (inclusive)
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// const gen_mat = () => {

//   let x = [];
//   for(let i=0;i < 300;i++){
//     x.push(randNum(0,100))
//   } 
//   return x;
// }

const psi = (n,L,x) => {
  return Math.sqrt(2/L) * Math.sin(n*Math.PI*x/L)
}


const psi_2 = (n,L,x) => {
  return psi(n,L,x)**2
}

const plob_ls = (x,y,z) => {
  let r = Math.sqrt(x*x + y*y + z*z)
  let p =  Math.exp(-r)/Math.sqrt(Math.PI)
  return [p*p,Array(x,y,z).join(",")]
} 

const plob_ls2 = (x,y,z) => {
  let r = Math.sqrt(x*x + y*y + z*z)
  let p = r* Math.exp(-r)/Math.sqrt(Math.PI*2)
  return [p*p,Array(x,y,z).join(",")]
} 

const plob_lpx = (x,y,z) => {
  let r = Math.sqrt(x*x + y*y + z*z)
  let px = x*Math.exp(-r)/Math.sqrt(Math.PI*2)/2
  let py = y*Math.exp(-r)/Math.sqrt(Math.PI*2)/2
  let pz = z*Math.exp(-r)/Math.sqrt(Math.PI*2)/2
  return [px*px,Array(x,y,z).join(",")]
} 


const plob_lpy = (x,y,z) => {
  let r = Math.sqrt(x*x + y*y + z*z)
  let py = y*Math.exp(-r)/Math.sqrt(Math.PI*2)/2
  return [py*py,Array(x,y,z).join(",")]
} 


const plob_lpz = (x,y,z) => {
  let r = Math.sqrt(x*x + y*y + z*z)
  let pz = z*Math.exp(-r)/Math.sqrt(Math.PI*2)/2
  return [pz*pz,Array(x,y,z).join(",")]
} 


const multPoints = (positions) => {
  let cloud = [];
  
  for(let i =0; i < positions.length;i+=3){
      let x= positions[i] ;
      let y= positions[i+1] ;
      let z= positions[i+2];

      let vector = new Vector2(x,y,z)

      let geometry = new BufferGeometry();
      geometry.setFromPoints([vector])

      let pointMaterial = new PointsMaterial({
        color:"0x00afaf",
        size: 0.25});
      let point = new Points(geometry,pointMaterial);  
      cloud.push(point);
  }
  
  return cloud

}

const  PElectron = (props) =>  {
    const torusRef = useRef();
    const attrib = useRef();

    let x=linspace(-2.0,2.0,30)
    let y=linspace(-2.0,2.0,30)
    let z=linspace(-2.0,2.0,30)

    let elements = []
    let mprobs = []
    
    x.forEach(ix => {
        y.forEach(iy => {
            z.forEach(iz => {
         //     elements.push(Array(ix,iy,iz).join(","))
                if(props.p === "x") {
                    mprobs.push(plob_lpx(ix,iy,iz))
                }else if (props.p === "y"){
                    mprobs.push(plob_lpy(ix,iy,iz))
                }else if (props.p === "z"){
                  mprobs.push(plob_lpz(ix,iy,iz))
                }
            })
        })
    }) 

    mprobs.sort((f,s)=> {return s[0] -f[0]})   
   
    let coords = []


    for (let i =0 ; i < 8000;i++){ 
        let coord = mprobs[i][1];
        coords.push(coord);
    }

    //console.log(coords)

    const vertices = [];

    coords.forEach(coord => {
      let mc =  coord.split(",")
      vertices.push(...mc);
    })  
  
    const positions = new Float32Array(vertices);


    let  mcolor 

    if(props.p === "x") {
      mcolor = Array(8000).fill([1.0,0.0,0.0])
    }else if(props.p === "y") {
      mcolor = Array(8000).fill([0.0,1.0,0.0])
    }else if(props.p === "z") {
      mcolor = Array(8000).fill([0.0,0.0,1.0])
    }
    
    const mcolors = new Float32Array(mcolor.flat());
   
    useFrame(() => {
     // torusRef.current.rotation.y += 0.01;
      if(props.p === "x") {
          torusRef.current.rotation.x += 0.01;
      }
      if(props.p === "y") {
        torusRef.current.rotation.y += 0.01;
      }

      if(props.p === "z") {
        torusRef.current.rotation.y += 0.01;
        torusRef.current.rotation.x += 0.01;
        
      }
    });


    return (<>
       <points ref={torusRef} position={props.position} rotation={[0,0,0]}>
        <bufferGeometry attach="geometry">
          <bufferAttribute
               attach="attributes-position"
              count={positions.length / 3}
              array={positions}
              itemSize={3}
              usage={three.DynamicDrawUsage}
            />
                     
            <bufferAttribute
               ref={attrib}
               attach="attributes-color"
              count={mcolors.length}
              array={mcolors}
              itemSize={3}
              usage={three.DynamicDrawUsage}
            />

             
        </bufferGeometry>
        <pointsMaterial size={0.1} sizeAttenuation={true}  vertexColors 
              transparent={true} opacity={0.7}
                />
    </points>
    
        </>);
}
 
export default PElectron;

import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { oConstants } from './common/oConstants';
import { Link } from "react-router-dom"; 
import TwitterConvTrkr from "react-twitter-conversion-tracker";

class ResultModal extends Component {
  constructor(props) {
     super(props)
     this.state = {
      close:false,
      show: false
    }
  }
  
  closeAnimation() {

    this.setState((prevState,props) => {
      prevState.show = false;
    })

  }

  tryMore = (e) => {
        this.props.handleClose();
  }

  goBack = (e) => {
     this.props.handleClose();
  }

 

    render() { 


      // TwitterConvTrkr.init("od7bm");
      //  TwitterConvTrkr.pageView();
        var message = ""
        var detMessage = ""
         

        return (<Modal  id="resultModal" show={this.props.show}
        centered
        size="sm">
          <Modal.Header>
            <Modal.Title> <span className="em"> Result </span></Modal.Title>
          </Modal.Header>
          <Modal.Body>
                  <h2> {message}  </h2>

                  <h3> {detMessage}  </h3>

          </Modal.Body>
           <Modal.Footer>
               <Button variant="warning" 
                      onClick={() => this.props.tryMore()}>
                 Try More
              </Button>
              <Link to="/">
                <Button variant="warning" 
                        onClick={() => this.props.goBack()}>
                  Go Back
                </Button>
              </Link>
          </Modal.Footer>
        </Modal>);
    }
}
 
export default ResultModal;
import React, { Component } from 'react';
import * as three from "three";

class Nucleus extends Component {
    state = {  } 
    render() { 
        return (<>
             <mesh>
                <sphereGeometry args={[0.2, 32,16]} />
                <meshBasicMaterial color={"black"} />
              </mesh>
          </>);
    }
}
 
export default Nucleus;

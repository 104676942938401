import React, { Component } from 'react';
import ReactDOM from 'react-dom/client';

import { Row,Col } from 'react-bootstrap';
import { Canvas } from "react-three-fiber";
import { OrbitControls, Sphere } from "@react-three/drei";


import Cube from '../Components/Cube';
import Ring from '../Components/Ring';
import Dot from '../Components/Dot';
import Nucleus from '../Components/Nucleus';
import Electron from '../Components/Electron';
import PElectron from '../Components/PElectron';
import { Camera } from 'three';

const Scene1S = (props) => {
    return (<>
        {/* <gridHelper/> */}
        {/* <axesHelper/> */}
        <pointLight position={[1, 2, -10]} />
        {/* <Cube/> */}
        {/* <Sphere/> */}
        <Nucleus/>
        <Electron s={1}/>
        <OrbitControls />
        {/* <Stats /> */}
      
    </>
    );
};

const Scene2S = (props) => {
    return (<>
        {/* <gridHelper/> */}
        {/* <axesHelper/> */}
        <pointLight position={[1, 2, -10]} />
        {/* <Cube/> */}
        {/* <Sphere/> */}
        <Nucleus/>
        <Electron s={1}/>
        <Electron s={2}/>
        <OrbitControls />
        {/* <Stats /> */}
      
    </>
    );
};

const Scene1PX = (props) => {
    return (<>
        {/* <gridHelper/> */}
        <axesHelper/>
        <pointLight position={[1, 2, -10]} />
        {/* <Cube/> */}
        {/* <Sphere/> */}
        <Nucleus/>
        <PElectron p={"x"} position={[0,0,0]} />
        <OrbitControls />
        {/* <Stats /> */}
      
    </>
    );
};

const Scene1PY = (props) => {
    return (<>
        {/* <gridHelper/> */}
        <axesHelper/>
        <pointLight position={[1, 2, -10]} />
        {/* <Cube/> */}
        {/* <Sphere/> */}
        <Nucleus/>
        <PElectron p={"y"} position={[0,0,0]} />
        <OrbitControls />
        {/* <Stats /> */}
      
    </>
    );
};

const Scene1PZ = (props) => {
    return (<>
        {/* <gridHelper/> */}
        <axesHelper/>
        <pointLight position={[1, 2, -10]} />
        {/* <Cube/> */}
        {/* <Sphere/> */}
        <Nucleus/>
        <PElectron p={"z"} position={[0,0,0]} />
        <OrbitControls />
        {/* <Stats /> */}
      
    </>
    );
};


class AtomicModel extends Component {
    constructor(props) {
        super(props)
        this.state = {  } 

    }
    componentDidMount() {
       this.createScene();
    }

    
    
    createScene = () => {
        const canvas1 = ReactDOM.createRoot(
            document.getElementById("model1s")
        );

        const canvas2 = ReactDOM.createRoot(
            document.getElementById("model2s")
        );

        const canvaspx = ReactDOM.createRoot(
            document.getElementById("model1px")
        );

        const canvaspy = ReactDOM.createRoot(
            document.getElementById("model1py")
        );
        const canvaspz = ReactDOM.createRoot(
            document.getElementById("model1pz")
        );

        canvas1.render(<>Hydrogen Model 1s
                <Canvas style={{ height: 200, width:200 }}
                            camera={{
                            fov: 75,
                            aspect: 2,
                            near: 0.1,
                            far: 10,
                            position: [0,0,5]
                            }}>     
                            <Scene1S />
                    </Canvas></>
        );

        canvas2.render(<> Hydrogen Model 2s
                            <Canvas style={{ height: 200, width:200 }}
                            camera={{
                            fov: 75,
                            aspect: 2,
                            near: 0.1,
                            far: 10,
                            position: [0,0,5]
                            
                            }}>     
                        <Scene2S />
                </Canvas></>
            );

            canvaspx.render(<>Hydrogen 1px model  
                <Canvas style={{ height: 200, width:200 }}
                            camera={{
                            fov: 75,
                            aspect: 2,
                            near: 0.1,
                            far: 10,
                            position: [0,0,5]
                            
                            }}>  
                    <Scene1PX />
                </Canvas>
                </> );

                canvaspy.render(<>Hydrogen 1py model  
                            <Canvas style={{ height: 200, width:200 }}
                                        camera={{
                                        fov: 75,
                                        aspect: 2,
                                        near: 0.1,
                                        far: 10,
                                        position: [0,0,5]
                                        
                                        }}>  
                                <Scene1PY />
                            </Canvas>
                            </> 
                    );
                    canvaspz.render(<>Hydrogen 1pz model 
                                        <Canvas style={{ height: 200, width:200 }}
                                                    camera={{
                                                    fov: 75,
                                                    aspect: 2,
                                                    near: 0.1,
                                                    far: 10,
                                                    position: [0,0,5]
                                                    
                                                    }}>  
                                            <Scene1PZ />
                                        </Canvas>
                                        </> 
                            );
    }

    render() { 
        return (
            <div className='container mcontainer'>
                <h5>Hydrogen atom electron probability distribution based on Schodinger Wave equation</h5>
                
             <Row>
                <Col><h6>Probability distributions for 1s and 2s orbitals. 
                    Denser regions indicates where electrons are more likely to exist.
                    Node, white area, between blue and red cloud indicate regions where an electron has zero probability of being found.</h6>
            </Col></Row>      
            <Row>
                <Col><div id="model1s"></div></Col>
                <Col><div id="model2s"></div></Col>
                <Col style={{fontSize:"60%",fontFamily:"Courier New",fontWeight:"bold"}}><br/>
                   <Row><Col><a href="https://winter.group.shef.ac.uk/orbitron">
                 Reference: Equations
                </a></Col></Row>
                   <Row>
                        <Col style={{textAlign:"right"}}>Radial wave function</Col> 
                        <Col style={{textAlign:"left"}}>R<sub>1s</sub> = 2 × Z<sup>3/2</sup> × e<sup>-ρ/2</sup></Col>
                    </Row>
                    <Row>
                        <Col style={{textAlign:"right"}}>Angular wave function</Col> 
                        <Col style={{textAlign:"left"}}> Y<sub>1s</sub> = 1 × (1/4π)<sup>1/2</sup></Col>
                    </Row>
                    <Row>
                        <Col style={{textAlign:"right"}}>Wave function</Col> 
                        <Col style={{textAlign:"left"}}> ψ<sub>1s</sub>	= R<sub>1s</sub> × Y1s <br/>
                        = 2Z<sup>3/2</sup>e<sup>-ρ/2</sup> × (1/4π)<sup>1/2</sup>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{textAlign:"right"}}>Electron density</Col> 
                        <Col style={{textAlign:"left"}}> ψ<sub>2</sub>px<sub>2</sub>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{textAlign:"right"}}>Radial distribution function</Col> 
                        <Col style={{textAlign:"left"}}> 4πr<sup>2</sup>ψ1s<sub>2</sub>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{textAlign:"left"}}>
                        r = radius in atomic units(1 Bohr radius = 52.9 pm)<br/>
                        π ~= 3.14159<br/>
                        e ~= 2.71828 <br/>
                        Z = effective nuclear charge for that orbital<br/>
                        ρ = 2Zr/n, n is the principal quantum number 
                        (1 for the 1s)
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col><h6>The p orbitals are shaped like dumbbells that are oriented along one of the axes—x,y,z.</h6>
            </Col></Row>  
            <Row><Col><div id="model1px"/></Col>
            <Col><div id="model1py"/></Col>
            <Col><div id="model1pz"/></Col>
            
            </Row>  
            </div> 
           
        );
    }
}
 
export default AtomicModel;
import {Component,props} from "react";
import { oConstants } from "./common/oConstants";
import { Row,Col,Card } from "react-bootstrap";
import './element.css'

class BElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name : this.props.name,
    }
  }

  getName = () =>{
    return this.name;
  } 

  handleClick = (e) => {
    var elem = e.target
    var name = elem.getAttribute("name");

    //playSound(name);

  }

  round = (x,y) => {
    if(x === null){
      return null;  
    }
    return x.toFixed(y)
  }

  render(){
     var {categories} = oConstants; 
     var {name,number,atomic_mass,symbol,melt,boil,density,phase,electron_configuration_semantic,
      electron_affinity,electronegativity_pauling,xpos,category,bohr_model_image,
            summary} 
              = this.props.elem; 

     console.log(bohr_model_image); 

     var  xp = 0
     
     var index = categories.indexOf(category);
     
     if(xpos) { 
      xp = xpos.toString().slice(-1);
     }

     var colorName = "Color" + (index +1) 

     if(category.includes("unknown")){
        colorName = "Color0" 
     }

     var {mtype} = this.props
     
     return (
        
        <div name={name} className={"BBlock "} id="BElem"
            category={category}
            onClick = {(e) => this.props.click(e)}
            >
           <div className="card">
              <div className="card-horizontal">
                  <div className="img-square-wrapper ephoto">
                      <img className="dImage" src={bohr_model_image}/>
                  </div>
                  <div className="card-body no-pad border-start border-primary" 
                        style={{fontSize:"8px"}}>
                    <Row className="row-striped">
                        <Col className="label" lg="2" md="2" sm="2" xs="2">Mass</Col>
                        <Col  className="value" lg="2" md="2" sm="2" xs="2">
                          {this.round(atomic_mass,3)}</Col>
                        <Col className="label" lg="3" md="3" sm="3" xs="3">Config.</Col>
                        <Col className="value" lg="5"  
                              md="5" sm="5" xs="5">{electron_configuration_semantic}</Col>
                    </Row>
                    <Row className="row-striped">
                        <Col className="label" lg="2"  md="2" sm="2" xs="2">Affinity</Col>
                        <Col className="value" lg="2" md="2" sm="2" xs="2">
                          {this.round(electron_affinity,3)}</Col>
                        <Col className="label" lg="2" md="2" sm="2" xs="2">Neg.</Col>
                        <Col className="value"  lg="2" md="2" sm="2" xs="2">{electronegativity_pauling}</Col>
                        <Col className="label" lg="2" md="2" sm="2" xs="2">Phase</Col>
                        <Col className="value"  lg="2" md="2" sm="2" xs="2">{phase}</Col>
                    </Row>
                    <Row className="row-striped">
                        <Col className="label" lg="2" md="2" sm="2" xs="2">Density</Col>
                        <Col className="value"  lg="2" md="2" sm="2" xs="2">{density}g/L</Col>
                        <Col className="label" lg="2" md="2" sm="2" xs="2">MP</Col>
                        <Col className="value"  lg="2">{melt}K</Col>
                        <Col className="label" lg="2" md="2" sm="2" xs="2">BP</Col>
                        <Col className="value"  lg="2" md="2" sm="2" xs="2">{boil}K</Col>
                    </Row>
                    <div  className="mhr"/>
                    <Row className="summary">
                        <Col lg="12" md="12" sm="12" xs="12">{summary}</Col>
                        
                    </Row>
                  </div>
              </div>
          </div>
           
            {/* <Row className="mprop">
            <Col></Col><Col>{ionization_energies}</Col>
            </Row>             */}
           
        </div>
    );
  }
}

export default BElement;
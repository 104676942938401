
import elemJSON from '../Assets/table.json'
import { oConstants } from './common/oConstants';




export function soundKeys() {
    
  

    if(oConstants.audios){
        return Object.keys(oConstants.audios)
    }
 
    return [];
}


export function playRandomSound(){
    
    if(!oConstants.soundKeys){
        var keys = soundKeys();
        oConstants.soundKeys = keys;
    }

    const random = Math.floor(Math.random() *  oConstants.soundKeys.length);
    var randomKey = oConstants.soundKeys[random];

    oConstants.audios[randomKey].play()
    oConstants.soundKeys  = oConstants.soundKeys .filter(item => item !== randomKey) 
    return randomKey;
}

export function matchEffect() {
    var elements = elemJSON.elements
    var map = {}
    for(var i=0;i < elements.length;i++) {
        var element = elements[i];
        var name = element["name"];
        map[name] = new Audio(require('../Sounds/' + name + ".mp3"))
    } 
 
     return map;
 } 

export function playSound(effectName) {
    var elements = elemJSON.elements
    if(oConstants.audios == null) {
        var audios = {} 
        for(var i=0;i < elements.length;i++) {
            var element = elements[i];
            var name = element["name"];

            var x = require('../Sounds/' + name + '.mp3');
            var y = new Audio(x)
            audios[name] = y
        } 
        oConstants.elements = elements
        oConstants.audios = audios; 
    }
    if(effectName === "init"){
        return;
    }

    if(!(effectName in oConstants.audios)) {
        var map = matchEffect();
        oConstants.audios[effectName] = map[effectName];
        var audio = matchEffect()[effectName];
        audio.play();
    }else{
        var audio = oConstants.audios[effectName];
        
        audio.play();
    }
}



import React, { Component } from 'react';
import * as three from "three";
// import {mesh,boxGeometry,meshStandardMaterial} from "three";

class Cube extends Component {
    state = {  } 
    render() { 
        return (<>
             <mesh rotation={[0, 0, 0]}>
                <boxGeometry args={[3, 3, 3]} />
                <meshStandardMaterial color="hotpink"  />
              
            </mesh>
          </>);
    }
}
 
export default Cube;

import { PointMaterial } from '@react-three/drei';
import linspace from 'exact-linspace';
import React, { useRef } from 'react';
import {useFrame } from "react-three-fiber";
import * as three from "three";

// import {mesh,boxGeometry,meshStandardMaterial} from "three";

function randNum(min, max) {
  // 👇️ get number between min (inclusive) and max (inclusive)
  return Math.random() * (max - min + 1) + min;
}

// const gen_mat = () => {

//   let x = [];
//   for(let i=0;i < 300;i++){
//     x.push(randNum(0,100))
//   } 
//   return x;
// }

const psi = (n,L,x) => {
  return Math.sqrt(2/L) * Math.sin(n*Math.PI*x/L)
}


const psi_2 = (n,L,x) => {
  return psi(n,L,x)**2
}

const plob_ls = (x,y,z) => {
  let r = Math.sqrt(x*x + y*y + z*z)
  let p =  Math.exp(-r)/Math.sqrt(Math.PI)
  return [p*p,Array(x,y,z).join(",")]
} 

const randomItem = (array) => {
  const randomObject = array[Math.floor(Math.random() * array.length)];
  return randomObject;
};



const  Dot = () =>  {
    const torusRef = useRef();

    let x=linspace(-1.0,1,30)
    let y=linspace(-1.0,1,30)
    let z=linspace(-1.0,1,30)

    let elements = []
    let mprobs = []

    x.forEach(ix => {
        y.forEach(iy => {
            z.forEach(iz => {
              elements.push(Array(ix,iy,iz).join(","))
              mprobs.push(plob_ls(ix,iy,iz))
            })
        })
    }) 

    mprobs.sort((f,s)=> {return s[0] -f[0]})   

    let coords = []

    for (let i =0 ; i < 8000;i++){ 
        let coord = mprobs[i][1];
        coords.push(coord);
    }

    //console.log(coords)

    const vertices = [];

    coords.forEach(coord => {
      let mc =  coord.split(",")
      vertices.push(...mc);
    })  

    const positions = new Float32Array(vertices);

    const op = "0.0".repeat(8000)
    const opacities = new Float32Array(op);


    useFrame(() => {
      torusRef.current.rotation.y += 0.01;
     
      // torusRef.current.rotation.x += 0.01;
      // torusRef.current.rotation.z += 0.01;
      // torusRef.current.rotation.x += 0.03;
      // torusRef.current.rotation.y += 0.03;
      // torusRef.current.rotation.y += 0.03;
    });
   
    return (<>

        <points ref={torusRef}>
        <bufferGeometry>
          <bufferAttribute
              attach="attributes-position"
              count={positions.length / 3}
              array={positions}
              itemSize={3}
              usage={three.DynamicDrawUsage}
            />

           
            {/* <bufferAttribute
              attach="attributes-color"
              count={colors.length / 3}
              array={colors}
              itemSize={3}
              usage={three.DynamicDrawUsage}
            /> */}
             
        </bufferGeometry>
        <pointsMaterial size={0.05} sizeAttenuation={true}  color={"gray"} 
              transparent={true} opacity={0.1}
                />
    </points>
    
        </>);
}
 
export default Dot;

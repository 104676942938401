import React, { Component } from 'react';
import Legend from '../Components/PeriodicLegend';
import PeriodicTable from '../Components/PeriodicTable';
import { oConstants } from '../Components/common/oConstants';
import  './game.css'

import { Row,Col,Button } from 'react-bootstrap';
import { playRandomSound, playSound } from '../Components/elemSound';
import anime from 'animejs';
import ResultModal from '../Components/ResultModal';
import BElement from '../Components/BElement';
import ReactDOM from 'react-dom/client';


import  '../Components/element.css'
import { location } from '../Components/common/mutils';


class MendTable extends Component {
    constructor(props){
        super(props);
        this.state = {
            
        };
        this.pref = React.createRef()

    }

    componentDidMount() {
        playSound("init");
      //  twq('event', 'tw-od7bm-odh62');

        //playRandomSound();
    }

    highlightCat = (e,show) => {
        if(show) {

            var table = this.pref.current;
            var divs = table.querySelectorAll('div[category]');
            var selCat = e.target.textContent
            divs.forEach(div => {
                var className = div.getAttribute("class");
                var class0 = div.classList[0]
                div.setAttribute("class", class0 + " Color100");
                div.setAttribute("class1",className);
                
      
            });

            var divC = Array.from(divs).filter(div => 
                    div.getAttribute("category").includes(selCat))
            

            divC.forEach( div => {
                var className = div.getAttribute("class1");
                div.setAttribute("class",className);
            })

        }else{
            var table = this.pref.current;
            var divs = table.querySelectorAll('div[category]');
            var selCat = e.target.textNode
            divs.forEach(div => {
                var className = div.getAttribute("class1");
                div.setAttribute("class",className);
                
      
            });
        }
    } 

    revealBlock = (name) => {
        if(oConstants.revealing) {
            setTimeout((name) => {
                    this.revealBlock(name)}
                ,1000);
            return;
        }
        oConstants.revealing = true

        var table = this.pref.current;
        var divs = table.querySelectorAll('div[category]');
        var divC = Array.from(divs).filter(div => 
                            div.getAttribute("name") == name)
        if(divC.length < 1) {
            oConstants.revealing = false;
            return;
        }
        var div = divC[0];   
        
        var oclassLists = div.classList;  
        var oClassName = div.getAttribute("class");  

        var nclassLists = [...oclassLists];
        nclassLists[0] = "Block"
        var nClassName = nclassLists.join(" ")    
        
        console.log("revealing block...")
        
        var anim = anime({
                targets: div,
                duration: 500,
                easing: 'easeInOutExpo',
                // rotateZ: ['100deg','260deg','0deg'],
                // rotateY: ['100deg','260deg','0deg'],
                rotateX: ['100deg','260deg','360deg'], 
                className:[oClassName,oClassName,oClassName,nClassName],
                opacity:['100%','25%','50%','100%'],
                offset:0,
                autoplay:false,
                complete : (anime) =>{
                    div.style["disabled"] = true
                    div.style["pointer-events"] = "none"
                    oConstants.isProcessing = false
                    oConstants.revealing = false
                    oConstants.currentQ = null;
                    this.startGame()
                }});           
        anim.play();         

    }

    startGame = () => {
        if(oConstants.currentQ){
            var cElem = oConstants.currentQ;
            var correct = cElem["name"];
            this.revealBlock(correct)
            return
        }
        var finished = false;
        var btnContent = "Skip to Next";
        var button = document.getElementById("startGame")
        if(oConstants.soundKeys?.length < 1) {
            finished = true;
            button.textContent = "Finished";

            return;
        }

        button.textContent = btnContent;
        
        var name = playRandomSound();
        var promptText = "Click on the right block for " + name;
        document.getElementById("prompt").textContent  = promptText
        var elem = oConstants.elements.filter(el => el["name"] === name);
        // document.getElementById("edesc").textContent  = elem[0]["summary"]
        oConstants.currentQ = elem[0]
    }

    answer = (e) => {
        var selBlock = e.target;
        var name = selBlock.getAttribute("name");
        if(!name) {
            selBlock = selBlock.parentNode;
            name = selBlock.getAttribute("name");
        } 
        console.log(name)
        var {elements} = oConstants
        var elems = elements.filter(el => el["name"] === name)
        var elem = elems[0];
        
        var descsEls = document.getElementsByClassName("faultElement");

        if(descsEls.length > 0) {
            descsEls[0].remove();
        }
       
        const canvas = ReactDOM.createRoot(
            document.getElementById("canvas")
        );
        var x = <BElement elem={elem}  mtype="BBlock"/>
        canvas.render(x);

        setTimeout(() => this.changeLoc(),300);    
            
        
    }

    changeLoc = () => {
        var table = this.pref.current;

        var divs = table.querySelectorAll('td[mcol="3"]');
        var col03 = divs[0];
        var loc = location(col03); 

        var bElem = document.getElementById("BElem").cloneNode(true); 
        bElem.style["left"] = loc.X +"px";
        bElem.style["top"] = (loc.Y+5) +"px";
        bElem.style["position"] = "absolute";
        bElem.style["z-index"] = "1000";

        bElem.classList.add("faultElement");
        
        document.body.appendChild(bElem);
    } 
    

    render() { 

        return (<div className='container mcontainer'>
            <Row>
                <Col className="col-lg-10">
                    <div  ref={this.pref}  className="Canvas">
                        <PeriodicTable click={this.answer}  mtype="Block"/>
                    </div>
               </Col>
            <Col className="col-lg-2">
                <div className="legend">
                    <Legend hoverLegend={this.highlightCat} 
                        />
                </div>
            </Col>
            </Row>
           
            
            <div id="canvas" className="elemDesc" style={{display:"none"}   }>
           
            </div>
           
             <ResultModal show={this.state.resultshow} 
                        goBack={this.goBack}
                        handleClose={this.resultClose} tryMore={this.tryMore} />
        </div>);
    }
}
 
export default MendTable;

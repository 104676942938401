import {Component,props} from "react";
import { oConstants } from "./common/oConstants";
import './element.css'
import { playSound } from "./elemSound";

class PElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name : this.props.name,
    }
  }

  getName = () =>{
    return this.name;
  } 

  handleClick = (e) => {
    var elem = e.target
    var name = elem.getAttribute("name");

    //playSound(name);

  }


  render(){
     var {categories} = oConstants; 
     var {name,number,mass,symbol,MP,BP,Density,Group,Configuration,pngname,fact,xpos,category} 
              = this.props.elem; 
     var  xp = 0
     
     var color = "#" + this.props.elem["cpk-hex"]
  
     var index = categories.indexOf(category);
     
     if(xpos) { 
      xp = xpos.toString().slice(-1);
     }

     var colorName = "Color" + (index +1) 

     if(category.includes("unknown")){
        colorName = "Color0" 
     }

     var {mtype} = this.props
     
     return (
        
        <div name={name} className={mtype + " " + colorName}
            category={category}
            onClick = {(e) => this.props.click(e)}
            >
             <span className="AN">{number}</span>
             <span className="Symbol">
                {symbol}
              </span>
              <span className="Name">{name.trim()}</span> 
        </div>
    );
  }
}

export default PElement;
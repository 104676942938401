import './App.css';
import PeriodicGame from './Views/PeriodicGame';
import Menu from './Components/Menu';

import {
  BrowserRouter as Router,
  Routes, Route

} from 'react-router-dom';
import MendTable from './Views/MendTable';
import AtomicModel from './Views/AtomicModel';
import Model3D from './Views/Model3D';


function App() {
  return (
    <div className="App">
      <Router>
          <div className="row heading">
              <div className="col"> <h3> <img style={{width:"40px"}} 
              src="https://www.edbetter.uk/wp-content/uploads/2020/02/images.jpg"/> 
              <a href="https://www.edbetter.uk/"><sub>Edbetter</sub></a></h3>
              </div>
            
            </div>
            <div className="centerMenu"><Menu/></div>
            
            <Routes>
                  <Route  path="/model3" element={<Model3D/>} />
                  <Route  path="/atom" element={<AtomicModel/>} />
                  <Route  path="/table" element={<MendTable/>} />
                  <Route  path="/practice" element={<PeriodicGame/>} />
                  <Route  path="" element={<MendTable/>} />
            </Routes>
        </Router>
    </div>
  );
}

export default App;

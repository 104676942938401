import React, { Component } from 'react';
import elemJSON from '../Assets/table.json'
import { oConstants } from './common/oConstants';
import PElement from './PElement';


class PeriodicTable extends Component {
    constructor(props) {
        super(props)
        this.state = {

        };
        this.elems =  [];

    }

    getElem = (i,j) => {
        var elems = this.elems.filter(e => e.xpos == i && e.ypos ==j)
        if(elems.length > 0) {
            return elems[0]
        }
        return undefined;
    }

    buildTable =  () => {
        var eJSON = this.getElements();
        var elements = eJSON.elements
        oConstants.elements = elements
        this.elems = elements;
        var maxX = Math.max(...elements.map(e => e.xpos));   

        var maxY = Math.max(...elements.map(e => e.ypos))
        
        var categories = new Set(elements.map(e => e.category))
        oConstants.categories = Array.from(categories)
        var eCount = 1;
        var rows = []
        var cols =[]
        for(var i = 1; i < maxY+1; i++){
            cols = []
            for(var j = 1; j < maxX+1; j++){
                var elem = this.getElem(j,i);
                if(elem) {
                    cols.push(<td key={eCount} className="grid-full" mcol={j}>
                        <PElement mtype={this.props.mtype}
                            click={(e) => this.props.click(e)} 
                            elem={elem}/>
                        </td>)
                }else{
                    cols.push(<td key={eCount} className="grid-empty" mcol={j}>&nbsp;</td>)

                }
                eCount++;
            }
            rows.push(<tr key={eCount}>{cols}</tr>)
        }

        return rows;
    
    }

    getElements = () => {
        return elemJSON;
    }


    render() { 
        return ( <>
                <table><tbody>{this.buildTable()}</tbody></table>
                
                </>);
    }
}
 
export default PeriodicTable;   
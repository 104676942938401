import React, { Component } from 'react';
import './element.css'
import { Row,Col } from 'react-bootstrap';
import { oConstants } from './common/oConstants';


class Legend extends Component {
    state = {  } 

    buildLegend = () => {
        var {categories} = oConstants;
        var cats = []
        var mkey = 0
        if(categories) {
            for (var i=0; i < categories.length; i++) {
                var category = categories[i];
                var colorName = "Color"+ (i+1)
                if(category.includes("unknown")){
                    category = category.slice(0,7)
                    colorName = "Color0"
                    i = 100
                }
                var cat = []
                cat.push(<span key={mkey++} style={{fontSize:"12px",textAlign:"right"}}>
                    {category}</span>)   
                cat.push(<span key={mkey++} className={colorName}/>)
                cats.push(<Row key={mkey++}>
                        <Col key={mkey++} className="catLegend"
                            onMouseEnter={(e) => this.props.hoverLegend(e,true)}
                            onMouseLeave={(e) => this.props.hoverLegend(e,false)}>
                            {cat}
                        </Col>
                    </Row>)
                    
            }
        } 
        return cats


    }

    render() { 
        return (<div className="dlegend" >
                {this.buildLegend()}
            </div>);
    }
}
 
export default Legend;